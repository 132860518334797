
import { dateFormat } from '@/utils/helper/format';
import { computed, onMounted, reactive, ref, toRefs, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { Actions } from '@/store/enums/StoreEnums';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import PartnerStoreDetailCard from '@/components/cards/PartnerStoreDetailCard.vue';
import moment from 'moment';
import { CarImage, Staff, VibeImage } from './model/partnerStore.model';

const carHeader = [
  { key: 'no', name: 'No.', sortable: true },
  { name: 'Type', key: 'type', sortable: false },
  { name: 'Brand', key: 'brand', sortable: false },
  { name: 'Model', key: 'model', sortable: false },
  { name: 'License', key: 'plate', sortable: false },
  { name: 'Series', key: 'series', sortable: false },
  { name: 'Status', key: 'verifyState', sortable: true },
  { name: 'Car Details', key: 'moreDetail', sortable: false },
];

const partnerHeader = [
  { key: 'no', name: 'No.', sortable: true },
  { name: 'User', key: 'partnerDetail', sortable: false },
  { name: 'Role', key: 'type', sortable: true },
  { name: 'Partner Details', key: 'moreDetail', sortable: false },
];
export default {
  name: 'PartnerVerifyWildcard',
  components: {
    Datatable,
    PartnerStoreDetailCard,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { id } = route.params;
    const mapRef = ref(null);
    const carTableRef = ref<any>(null);
    const partnerTableRef = ref<any>(null);
    const state = reactive({
      loaded: false,
      data: {} as any,
      carImages: [] as CarImage[],
      vibeImages: [] as VibeImage[],
      staffs: [] as Staff[],
      currentImage: '' as string,
      carTableOption: {
        tableHeader: carHeader,
        tableData: [],
        loading: false,
        total: 0,
        rowsPerPage: 10,
        currentPage: 1,
      },
      partnerTableOption: {
        tableHeader: partnerHeader,
        tableData: [],
        loading: false,
        total: 0,
        rowsPerPage: 10,
        currentPage: 1,
      },
    });

    const timeFormat = [
      'licenceExpireDate',
      'insuranceExpireDate',
      'compulsoryInsuranceExpireDate',
    ];

    const fetchCar = async (page = 1, rowsPerPage = 10) => {
      try {
        const queryCondition: any = {
          page,
          pageSize: rowsPerPage,
          store: id,
        };

        const { data } = await ApiService.get(
          `/car?${new URLSearchParams(queryCondition)}`
        );
        if (data.status) {
          state.carTableOption.tableData = data.datas;
          state.carTableOption.total = data.total;
        }
        state.carTableOption.loading = false;
      } catch (error) {
        state.carTableOption.loading = false;
      }
    };

    const fetchPartner = async (page = 1, rowsPerPage = 10) => {
      try {
        const queryCondition: any = {
          registerState: 'Done',
          page,
          pageSize: rowsPerPage,
          // organization: id,
        };

        const { data } = await ApiService.get(
          `/partner?${new URLSearchParams(queryCondition)}`
        );

        if (data.status) {
          state.partnerTableOption.tableData = data.datas;
          state.partnerTableOption.total = data.total;
        }
        state.partnerTableOption.loading = false;
      } catch (error) {
        state.partnerTableOption.loading = false;
      }
    };

    onMounted(async () => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading');

      const [res] = await Promise.all([
        ApiService.get(`/store/${id}`),
        fetchCar(),
        fetchPartner(),
      ]);

      state.data = res.data.data;

      setCurrentPageBreadcrumbs(
        'Partner Store',
        [{ name: 'Store List', path: '/stores' }, 'Store Detail'],
        {}
      );

      state.loaded = true;
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading');
    });

    const changeImage = (image: string) => {
      state.currentImage = image;
    };
    const coordinates = computed(() => {
      return state.data.location &&
        state.data.location.coordinates &&
        state.data.location.coordinates.length
        ? state.data.location.coordinates
        : [0, 0];
    });

    watch(
      () => [
        partnerTableRef.value?.pagination?.rowsPerPage,
        partnerTableRef.value?.pagination?.page,
      ],
      async ([rows, page]) => {
        state.partnerTableOption.currentPage = page;
        state.partnerTableOption.rowsPerPage = rows;

        if (
          state.partnerTableOption.rowsPerPage + 1 >
          state.partnerTableOption.total
        ) {
          state.partnerTableOption.currentPage = 1;
        }

        await fetchPartner(
          state.partnerTableOption.currentPage,
          state.partnerTableOption.rowsPerPage
        );
      }
    );

    watch(
      () => [
        carTableRef.value?.pagination?.rowsPerPage,
        carTableRef.value?.pagination?.page,
      ],
      async ([rows, page]) => {
        state.carTableOption.currentPage = page;
        state.carTableOption.rowsPerPage = rows;

        if (state.carTableOption.rowsPerPage + 1 > state.carTableOption.total) {
          state.carTableOption.currentPage = 1;
        }

        await fetchCar(
          state.carTableOption.currentPage,
          state.carTableOption.rowsPerPage
        );
      }
    );

    return {
      mapRef,
      carTableRef,
      partnerTableRef,
      ...toRefs(state),
      carHeader,
      carData: [],
      changeImage,
      dateFormat,
      timeFormat,
      moment,
      coordinates,
      placeholderImage: 'media/images/steam-question-mark-icon-29.jpg',
    };
  },
};
