
import { reactive, toRefs, watch } from 'vue';

export default {
  name: 'PartnerDetailsCard',
  props: {
    data: {
      type: Object,
      required: true,
    },
    carCount: {
      type: Number,
      required: true,
    },
    staffCount: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const placeholderImage = 'media/images/steam-question-mark-icon-29.jpg';
    const state = reactive({
      partnerStoreData: props.data,
    });

    const changeImage = (image) => {
      emit('change-image', image);
    };

    watch(
      () => [props.data],
      () => {
        state.partnerStoreData = props.data;
      }
    );

    return { ...toRefs(state), changeImage, placeholderImage };
  },
};
